import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';
import styled, { css } from 'styled-components';

import { RenderingQuestion } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';
import { PdfLabelText, PdfFieldGroupHeader } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import { FieldWrapperA } from '../../../PdfFieldGeneration/FieldVariantA/FieldWrapperA';

type Props = {
  fieldGroups: RenderingQuestion[];
};

const FieldContainer = styled.div<{ pageBreakInside: string }>`
  ${(props) => {
    return css`
      page-break-inside: ${props.pageBreakInside};
    `;
  }}
`;

export const FIELD_GROUP_TEST_IDS = {
  FIELD_GROUP_TITLE: 'FieldGroup:fieldGroupTitle',
};

export function FieldGroups(props: Props): React.ReactElement | null {
  const { fieldGroups } = props;
  const { componentSelection = [] } = usePdfContext();
  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { avoidFieldGroupPageBreak = true } = questionnaireSettings;
  const pageBreakInside = avoidFieldGroupPageBreak ? 'avoid' : 'auto';

  return (
    <React.Fragment>
      {fieldGroups.map((fieldGroup: RenderingQuestion) => {
        const { title: fieldGroupTitle, text: fieldGroupText, id, visible } = fieldGroup;
        if (!visible) return null;

        return (
          <React.Fragment key={id}>
            <FieldContainer key={id} pageBreakInside={pageBreakInside}>
              {fieldGroupTitle && (
                <Box mb={1}>
                  <PdfFieldGroupHeader data-testid={FIELD_GROUP_TEST_IDS.FIELD_GROUP_TITLE}>
                    {ReactHtmlParser(fieldGroupTitle)}
                  </PdfFieldGroupHeader>
                </Box>
              )}
              {fieldGroupText && (
                <Box mb={2} mt={-1}>
                  <PdfLabelText>{ReactHtmlParser(fieldGroupText)}</PdfLabelText>
                </Box>
              )}
              <Box mb={2}>
                <FieldWrapperA fieldGroup={fieldGroup} />
              </Box>
            </FieldContainer>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
