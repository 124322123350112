import React from 'react';

import usePdfContext from '../../../../Context/usePdfContext';
import { formatPhoneNumber, formatFullDate, formatAddress } from './Helpers/variantPdfHelper';
import { renderColumnBlock, renderColumnBlockWithIndent } from './RenderColumn';
import { ColumnContainer, Column, Section, Separator, Subtitle } from './Styles/PdfVariantBStyles';

export function OwnerInformation(): React.ReactElement | null {
  const { ownerInformation = [] } = usePdfContext();

  return (
    <Section>
      {ownerInformation.map((owner: any, index: number) => (
        <React.Fragment key={index}>
          <Subtitle>Propriétaire du contrat</Subtitle>
          <Separator />
          <ColumnContainer>
            <Column width={65}>
              <div>
                {'Prénom et nom:'} <strong>{owner.firstName + ' ' + owner.lastName}</strong>
              </div>
              {renderColumnBlockWithIndent(
                'Adresse',
                formatAddress(owner.address, owner.address2, owner.city, owner.provinceOrState, owner.postalCode)
              )}
              {renderColumnBlockWithIndent('Courriel', owner.email)}
              {renderColumnBlockWithIndent('Tél', formatPhoneNumber(owner.phoneNumber))}
            </Column>
            <Column width={35}>{renderColumnBlock(`Date de naissance: ${formatFullDate(owner.dateOfBirth)}`)}</Column>
          </ColumnContainer>
        </React.Fragment>
      ))}
    </Section>
  );
}
