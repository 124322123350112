import React from 'react';

import { RenderingSignatureField } from '@breathelife/questionnaire-engine';
import { OneSpanESignSigner } from '@breathelife/types';
import { PdfPaddedGridItem } from '@breathelife/ui-components';

import usePdfContext from '../../../Context/usePdfContext';
import { generateLabelText, generateOneSpanTextTag } from '../../../helpers/signatureHelpers';
import { SignatureFieldType, SigneeLabel } from '../../../types';
import { QuestionAnswerGroupA } from '../../PdfComponents/ComponentVariants/ComponentVariantsA/Styles/PdfQuestionAnswerGroupA';
import { SignaturesContainer } from './Styles/FieldVariantBStyles';

type Props = {
  field: RenderingSignatureField;
};

export function Signature(props: Props): React.ReactElement | null {
  const { field } = props;
  const { participantRole } = field;
  const { application, signatureData, displaySigneeLabel = SigneeLabel.default } = usePdfContext();

  if (!signatureData) {
    return null;
  }
  const { lang: language } = application;

  const { signers, eSignatureFieldType } = signatureData;

  const signees = signers[participantRole];

  if (!signees || !signees.length) {
    return null;
  }

  const signeeCount = signees.length;

  return (
    <SignaturesContainer xs={12}>
      {signees.map((signee: OneSpanESignSigner, index: number) => (
        <PdfPaddedGridItem key={index} item xs={4} data-testid='Signature'>
          <QuestionAnswerGroupA
            labelText={generateLabelText(signee, index + 1, displaySigneeLabel, participantRole, language, signeeCount)}
            value={generateOneSpanTextTag(signee.oneSpanRole, eSignatureFieldType ?? SignatureFieldType.capture, {
              x: 120,
              y: 30,
            })}
            isSignature
          />
        </PdfPaddedGridItem>
      ))}
    </SignaturesContainer>
  );
}
