import styled, { css } from 'styled-components';

type AvoidInnerPageBreak = { avoidInnerPageBreak?: boolean };
type ColumnProps = { width?: number };

export const Container = styled.div`
  color: ${(props) => props.theme.colors.text.base};
  line-height: 1.4;
  margin: 0 auto;
  max-width: 1000px;
  font-size: 11px;
`;

export const ListCounterContainer = styled.div`
  counter-reset: item;
`;

export const DocumentTitle = styled.h1`
  margin-bottom: 0px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

export const Subtitle = styled.h2<{ margin?: string }>`
  color: ${(props) => props.theme.colors.text.base};
  font-size: 16px;
  font-weight: bold;
  margin: ${(props) => props.margin ?? '10px 0 5px 0'};
`;

export const Header = styled.header`
  text-align: center;
  margin-bottom: 1em;

  & > div {
    font-size: 11px;
  }
`;

export const ReferenceNumber = styled.div`
  text-align: right;
`;

export const Section = styled.div<AvoidInnerPageBreak>`
  page-break-inside: ${(props) => (props.avoidInnerPageBreak ?? false ? 'avoid' : 'auto')};
  margin-bottom: 2em;
`;

export const SubSection = styled.div`
  margin-bottom: 1.5em;
`;

export const SectionContainer = styled.div``;

export const SubSectionContainer = styled.div<{ pageBreak: boolean }>`
  ${({ pageBreak }) =>
    pageBreak
      ? css`
          break-before: page;
        `
      : css`
          page-break-inside: avoid;
        `}
`;

export const SectionTable = styled.table`
  text-align: left;
  width: 100%;

  & > tbody {
    line-height: 14px;
  }
`;

export const SectionTableHeader = styled.thead`
  border-bottom: 1px double ${(props) => props.theme.colors.text.light};
`;

export const SectionTableFooter = styled.tfoot`
  & th:first-of-type {
    text-align: right;
  }
`;

export const SectionTableRow = styled.tr``;

export const SingleItem = styled.div`
  display: flex;
`;

export const ContentRow = styled(SectionTableRow)`
  & > td {
    padding-top: 4px;
    font-size: 11px;
  }
`;

export const SubContentRow = styled(SectionTableRow)`
  & > td {
    padding-top: 4px;
    font-size: 11px;

    &:first-child {
      padding-left: 12px;
    }
  }
`;

export const OptionsTitle = styled.td`
  padding-top: 18px !important;
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
`;

export const TableLine = styled.td`
  border-bottom: 1px solid black;
`;

export const RightAlignContent = styled.td`
  text-align: right;
  padding-right: 0.75em;
  font-size: 11px;
`;

export const List = styled.ol`
  list-style-type: none;
`;

export const NumberedList = styled.ol`
  list-style-type: decimal;
`;

export const ListItem = styled.li`
  border-bottom: 2px solid ${(props) => props.theme.colors.text.base};
  padding: 5px 0 10px 0;
  page-break-inside: avoid;
  list-style-position: inside;

  &:last-of-type {
    border-bottom: 0;
  }
  &:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
    display: hidden;
  }
`;

export const ListItemSingleLine = styled(ListItem)`
  display: flex;
  &:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
    display: hidden;
  }
`;

export const Li = styled.li`
  margin-left: 2em;
`;

export const ListItemAlphabet = styled(Li)`
  display: flex;
  counter-increment: alphaSubOption;
  &:before {
    width: 1.5em;
    content: counter(alphaSubOption, lower-alpha) '.';
  }
`;

export const Sublist = styled.ol`
  list-style-type: lower-latin;
  margin-top: 1em;
  padding-left: 1em;
  counter-reset: item;
`;

export const SublistItem = styled.li`
  display: flex;
  &:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }
`;

export const SubOptionalList = styled(Sublist)`
  margin-top: 0px;
  counter-reset: unset;
  margin-left: 1.5em;
`;

export const SubOptionalListItem = styled.div`
  padding-right: 3em;
  padding-left: 2em;
`;

export const ListItemTitle = styled.div`
  display: inline;
  margin-left: 0.5em;
`;

export const ListItemText = styled.div`
  display: inline;
  margin-left: 0.75em;
  margin-right: 2.5em;
`;

export const ListItemTextTitle = styled.div`
  display: inline;
  margin-left: 0.35em;
`;

export const RightAlignValue = styled.div`
  margin-left: auto;
`;

export const SubListItemText = styled.div`
  display: inline-flex;
  width: 75%;
  margin-left: 0.7em;
`;

export const SmallItemText = styled(SubListItemText)`
  width: 60%;
`;

export const P = styled.p`
  margin: 1em 0;
  page-break-inside: avoid;
  font-size: 11px;
`;

export const PdfVariantFieldText = styled.p`
  font-size: 11px;
`;

export const PdfVariantFieldGroupHeader = styled.p`
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  overflow-wrap: anywhere;
  margin-bottom: 0;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Column = styled.div<ColumnProps>`
  padding-right: 20px;
  width: ${(props) => (props.width ? `${props.width}%` : '40%')};
  line-height: 14px;
  & > * {
    font-size: 11px;
  }
`;

export const ColumnTitle = styled.span`
  min-width: 4.2em;
`;

export const Date = styled.span`
  margin-right: 2em;
`;

export const IndentedBlock = styled.div`
  margin-left: 1.75em;
`;

export const TextBlock = styled.div`
  display: inline-block;
  margin-left: 0.75em;
`;

export const Italicized = styled.span`
  font-style: italic;
`;

export const RowSpacing = styled.div`
  display: flex;
  margin: 10px 0 10px 0;
`;

export const Signature = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const SignatureInfo = styled.div`
  text-align: center;
  width: 55%;
  word-wrap: break-word;
`;

export const DigitalSignature = styled.div`
  margin: 0 7px;
  min-height: 1em;
`;

export const ApprovedContainer = styled.div`
  position: relative;
  margin-top: -15px;
  height: 80px;
  flex: 1;
`;

export const ApprovedImage = styled.img`
  margin: auto;
  max-height: 80px;
  width: auto;
`;

export const Separator = styled.hr`
  height: 0;
  background-color: ${(props) => props.theme.colors.text.base};
`;

export const TabSpace = styled.div`
  padding-right: 2em;
  display: inline;
`;

export const SignatureLine = styled(Separator)`
  margin: 0;
`;
