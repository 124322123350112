import { AxiosResponse } from 'axios';

import { Questionnaire as EngineQuestionnaire, AnswerPath } from '@breathelife/questionnaire-engine';
import { DynamicPdfDataGetRequest, DynamicPdfType, Theme } from '@breathelife/types';

import ApiService from './ApiService';

export async function fetchDynamicPdfData(
  applicationId: string,
  dynamicPdfType: DynamicPdfType
): Promise<DynamicPdfDataGetRequest<EngineQuestionnaire, AnswerPath>> {
  const insuranceProductsResponse = await ApiService.getDynamicPdfData<EngineQuestionnaire, AnswerPath>(
    applicationId,
    dynamicPdfType
  );
  return insuranceProductsResponse.data;
}

export async function fetchTheme(): Promise<AxiosResponse<Theme>> {
  return ApiService.fetchTheme();
}
