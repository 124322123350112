export default {
  A: {
    header: 'Agent',
    name: "Nom de l'Agent",
    number: "Numéro de l'Agent",
    email: 'Courriel',
    phoneNumber: 'Numéro de téléphone',
  },
  B: {},
};
