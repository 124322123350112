import { FieldTypes } from '@breathelife/questionnaire-engine';
import { DynamicPdfFieldProps } from '@breathelife/types';

import { AgreeField } from './AgreeField';
import { Autocomplete } from './Autocomplete';
import { Button } from './Button';
import { CheckboxField } from './CheckboxField';
import { CheckboxGroup } from './CheckboxGroup';
import { CurrencyCard } from './CurrencyCard';
import { Date } from './Date';
import { Dropdown } from './Dropdown';
import { Information } from './Information';
import { Input } from './Input';
import { Money } from './Money';
import { Number } from './Number';
import { Phone } from './Phone';
import { Radio } from './Radio';
import { Signature } from './Signature';
import { TextArea } from './TextArea';
import { YearMonth } from './YearMonth';

const FieldListB: Record<FieldTypes, React.FunctionComponent<DynamicPdfFieldProps>> = {
  agree: AgreeField,
  checkbox: CheckboxField,
  input: Input,
  textarea: TextArea,
  radio: Radio,
  dropdown: Dropdown,
  date: Date,
  yearMonth: YearMonth,
  number: Number,
  money: Money,
  checkboxGroup: CheckboxGroup,
  phone: Phone,
  currencyCard: CurrencyCard,
  button: Button,
  autocomplete: Autocomplete,
  information: Information,
  signature: Signature,
};

export default FieldListB;
