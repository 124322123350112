import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PdfVariant } from '@breathelife/types';

import { PdfContainer } from './Components/PdfContainer';
import { setDynamicPdfTranslations } from './Localization/Localizer';
import { DynamicPdfProps } from './types';

export type { SignatureFieldType } from './types';

function DynamicPdf(props: DynamicPdfProps): React.ReactElement {
  const { pdfTextOverrides = {} } = props;
  // TODO: Replace when doing components variants structure work
  const mockComponentsVariantsTree = {
    advisorInformationVariant: PdfVariant.A,
    insuranceFirmInformationVariant: PdfVariant.A,
    ownerInformationVariant: PdfVariant.A,
    payorInformationVariant: PdfVariant.A,
    pdfTitleVariant: PdfVariant.A,
    productInformationVariant: PdfVariant.A,
    proposedInsuredInformationVariant: PdfVariant.A,
    submissionDateVariant: PdfVariant.A,
    carrierInformationVariant: PdfVariant.A,
  };
  useEffect(() => {
    setDynamicPdfTranslations(pdfTextOverrides, mockComponentsVariantsTree);
  }, [pdfTextOverrides]);
  return (
    <Switch>
      <Route path={'/pdf/:type/:applicationId'}>
        <PdfContainer {...props} />
      </Route>
    </Switch>
  );
}

export default DynamicPdf;
