import { TimeOfDay, Language } from '@breathelife/types';

import { toCurrency } from '../../../../../helpers/currency';
import { formatDate } from '../../../../../helpers/date';

export const formatPhoneNumber = (phoneNumber: string): string => {
  const splicedPhoneNumber: RegExpMatchArray | null = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (splicedPhoneNumber) {
    return `${splicedPhoneNumber[1] ? splicedPhoneNumber[1] + ' ' : ''}${splicedPhoneNumber[2]} ${
      splicedPhoneNumber[3]
    }-${splicedPhoneNumber[4]}`;
  } else {
    return '';
  }
};

export const formatFullDate = (date: string | Date): string => {
  return formatDate(date, Language.fr, true);
};

export const formatBankNumber = (pad: {
  branchNumber: string;
  financialInstitutionNumber: string;
  accountNumber: string;
}): string => {
  if (typeof pad !== 'undefined') {
    const { branchNumber, financialInstitutionNumber, accountNumber } = pad;
    if (branchNumber && financialInstitutionNumber && accountNumber) {
      const noOfDigitsReq = 4;
      const obfuscatedAccount = '*'
        .repeat(accountNumber.length - noOfDigitsReq)
        .concat(accountNumber.slice(-noOfDigitsReq));
      return `${'*'.repeat(branchNumber.length)}-${'*'.repeat(financialInstitutionNumber.length)}-${obfuscatedAccount}`;
    }
  }
  return '';
};

export const formatAddress = (
  address: string,
  address2: string,
  city: string,
  provinceOrState: string,
  postalCode: string
): string => {
  return `${[address, address2].filter(Boolean).join(' ')}, ${[city, provinceOrState, postalCode]
    .filter(Boolean)
    .join(', ')}`;
};

export const formatTimeOfDay = (timeOfDay: string): string => {
  switch (timeOfDay) {
    case TimeOfDay.day:
      return 'Jour';
    case TimeOfDay.night:
      return 'Soir';
    case TimeOfDay.all:
      return 'Jour et Soir';
    default:
      return 'Jour et Soir';
  }
};

type CurrencyOptions = Pick<Intl.NumberFormatOptions, 'minimumFractionDigits'>;

export const formatCurrency = (
  amount: number,
  currency: string,
  language: Language,
  options: CurrencyOptions = {}
): string => {
  return toCurrency(amount, currency, language, options).replace(/[a-zA-Z]/g, '');
};
