export default {
  A: {
    applicationCreatedOn: `Application created on`,
    applicationCompletedOn: `Application completed on`,
    insuredName: `Name of Insured`,
    insuredEmail: `Insured's Email`,
    referenceNumber: `Reference Number`,
    id: `ID`,
  },
  B: {
    applicationCreatedOn: `Application created on`,
    applicationCompletedOn: `Application completed on`,
    insuredName: `Name of Insured`,
    insuredEmail: `Insured's Email`,
    referenceNumber: `Reference Number`,
    id: `ID`,
  },
};
