export default {
  A: {
    coverageInformation: 'Information de la couverture',
    product: {
      one: 'Protection',
      other: 'Protections',
    },
    productDescription: 'Nom du produit',
    coverage: 'Couverture',
    monthlyPremium: 'Prime mensuelle',
    addonOptionPrefix: 'Option: ',
    addonDescription: 'Options additionnelles',
    includedAddon: 'Inclus',
    totalMonthlyPremium: 'Prime mensuelle totale',
    riderDescription: 'Nom du rider',
  },
  B: {},
};
