import React from 'react';

import { DynamicPdfComponentName, DynamicPdfComponentProps } from '@breathelife/types';

import { Questionnaire } from '../../QuestionnaireVariants/QuestionnaireVariantsA/Questionnaire';
import { AdvisorInformation } from './AdvisorInformation';
import { CarrierInformation } from './CarrierInformation';
import { CryptoSignature } from './CryptoSignature';
import { ESignatureContainer } from './ESignatureContainer';
import { InsuranceFirmInformation } from './InsuranceFirmInformation';
import { OwnerInformation } from './OwnerInformation';
import { PayorInformation } from './PayorInformation';
import { PdfTitle } from './PdfTitle';
import { ProductInformation } from './ProductInformation';
import { ProposedInsuredInformation } from './ProposedInsuredInformation';
import { SubmissionDate } from './SubmissionDate';

const ComponentListA: { [key in DynamicPdfComponentName]: React.FunctionComponent<DynamicPdfComponentProps> } = {
  ProductInformation: ProductInformation,
  ProposedInsuredInformation: ProposedInsuredInformation,
  OwnerInformation: OwnerInformation,
  PayorInformation: PayorInformation,
  AdvisorInformation: AdvisorInformation,
  InsuranceFirmInformation: InsuranceFirmInformation,
  PdfTitle: PdfTitle,
  SubmissionDate: SubmissionDate,
  CryptoSignature: CryptoSignature,
  Questionnaire: Questionnaire,
  ESignatureContainer: ESignatureContainer,
  CarrierInformation: CarrierInformation,
};

export default ComponentListA;
