export default {
  A: {
    applicationCreatedOn: `Application créée le`,
    applicationCompletedOn: `Application complétée le`,
    insuredName: `Nom de l'assuré`,
    insuredEmail: `Courriel de l'assuré`,
    referenceNumber: `Numéro de référence`,
    id: `ID`,
  },
  B: {
    applicationCreatedOn: `Application créée le`,
    applicationCompletedOn: `Application complétée le`,
    insuredName: `Nom de l'assuré`,
    insuredEmail: `Courriel de l'assuré`,
    referenceNumber: `Numéro de référence`,
    id: `ID`,
  },
};
