import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';

import { DynamicPdfComponentName } from '@breathelife/types';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatDateWithTime } from '../../../../helpers/date';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import { Header } from './Styles/PdfVariantBStyles';

// Configure dayjs to support YYYY-MM-DD hh:mm ET format
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export function SubmissionDate(): React.ReactElement | null {
  const { application, timezone, componentSelection = [] } = usePdfContext();
  const { submissionDate, lang: language } = application;

  const submissionDateSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.SubmissionDate);
  const { displayFullDate = false } = submissionDateSettings;

  if (!submissionDate) {
    return null;
  }

  const regionTimezone = timezone ? timezone : 'America/Toronto';
  const formattedSubmissionDate = formatDateWithTime(submissionDate, language, regionTimezone, displayFullDate);
  const submissionDateText = translate('submissionDate', { locale: language, submissionDate: formattedSubmissionDate });

  return (
    <React.Fragment>
      <Header>{submissionDateText}</Header>
    </React.Fragment>
  );
}
