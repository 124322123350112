import axios from 'axios';

import config from '@breathelife/config/frontend';
import {
  addJwtIntoHeaderInterceptor,
  addTraceIntoHeaderInterceptor,
  Gateway,
  sentryInterceptor,
} from '@breathelife/gateway';

import { getTraceId } from '../helpers/monitoring';

const ApiService = axios.create();
const traceId = getTraceId();

ApiService.interceptors.request.use(addTraceIntoHeaderInterceptor(traceId));
ApiService.interceptors.response.use((response) => response, sentryInterceptor);

let authorizationInterceptorIndex: number;
// Add header authorization token
export function setAuthorizationInterceptor(token?: string): void {
  if (typeof authorizationInterceptorIndex !== 'undefined') {
    ApiService.interceptors.request.eject(authorizationInterceptorIndex);
  }

  authorizationInterceptorIndex = ApiService.interceptors.request.use(addJwtIntoHeaderInterceptor(token));
}

const releaseVersion = config.get<string>('git.commitSha');

export default new Gateway(releaseVersion, ApiService);
