export default {
  A: {
    header: 'Agent',
    name: 'Agent Name',
    number: 'Agent Number',
    email: 'Email',
    phoneNumber: 'Phone Number',
  },
  B: {},
};
