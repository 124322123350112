export default {
  A: {
    header: 'Insurance Company Information',
    name: 'Name',
    address: 'Mailing Address',
    primaryPhoneNumber: 'Primary Phone Number',
    tollFreePhoneNumber: 'Toll-free Phone Number',
    email: 'Email',
    website: 'Website',
  },
  B: {},
};
