import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styled from 'styled-components';

import { ProductsEntityProduct, ProductAddon, DynamicPdfComponentName, PaymentFrequency } from '@breathelife/types';
import { PdfFieldGroupHeader } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { toCurrency } from '../../../../helpers/currency';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

const paymentFrequencyLabelLookup: { [key in PaymentFrequency]: string } = {
  [PaymentFrequency.monthly]: 'productInformation.monthlyPremium',
  [PaymentFrequency.annually]: 'productInformation.annualPremium',
  [PaymentFrequency.semiAnnually]: 'productInformation.semiAnnualPremium',
  [PaymentFrequency.quarterly]: 'productInformation.quarterlyPremium',
};

const totalPaymentFrequencyLabelLookup: { [key in PaymentFrequency]: string } = {
  [PaymentFrequency.monthly]: 'productInformation.totalMonthlyPremium',
  [PaymentFrequency.annually]: 'productInformation.totalAnnualPremium',
  [PaymentFrequency.semiAnnually]: 'productInformation.totalSemiAnnualPremium',
  [PaymentFrequency.quarterly]: 'productInformation.totalQuarterlyPremium',
};

const ProductContainer = styled.div`
  & {
    break-inside: avoid;
  }
`;

const RowGrid = styled(Grid)`
  & > div {
    height: 100%;
  }
  .labelText {
    height: 25%;
  }
  .valueText {
    height: 75%;
    & > div {
      height: 80%;
    }
  }
`;

export function ProductInformation(): React.ReactElement | null {
  const { productsEntity, application, currency, addons, componentSelection = [] } = usePdfContext();
  const { premium, lang: language } = application;

  if (typeof productsEntity === 'undefined') {
    return null;
  }

  const productInformationSettings = getComponentSettings(
    componentSelection,
    DynamicPdfComponentName.ProductInformation
  );
  const { fontWeight, zeroCoverageMask } = productInformationSettings;

  // Only render the labels for the first row
  const label = (index: number, translation: string): string => {
    return index === 0 ? translate(translation, { locale: language }) : '';
  };

  // FIXME: Align carriers to use product-premium nodeId
  const productPremium = (productPremium: number): number => {
    if (premium && !productPremium) {
      return premium;
    }
    return productPremium;
  };

  return (
    <Box mb={3}>
      <ProductContainer>
        <PdfFieldGroupHeader data-testid='ProductInformation'>
          {translate('productInformation.product', { locale: language, count: 2 })}
        </PdfFieldGroupHeader>
        <Grid container>
          <Grid item xs={9}>
            <React.Fragment>
              <Grid container>
                {productsEntity.products.map((product: ProductsEntityProduct, index: number) => (
                  <React.Fragment key={product.productId}>
                    <RowGrid item xs={6}>
                      <QuestionAnswerGroupA
                        labelText={label(index, 'productInformation.productDescription')}
                        value={product.name[language] || ''}
                        valueWeight={fontWeight || 600}
                        alignLabelWithValue={true}
                      />
                    </RowGrid>
                    <RowGrid item xs={3}>
                      <QuestionAnswerGroupA
                        labelText={label(index, 'productInformation.coverage')}
                        value={toCurrency(product.coverageAmount, currency, language)}
                        valueWeight={fontWeight || 600}
                        alignLabelWithValue={true}
                      />
                    </RowGrid>
                    <RowGrid item xs={3}>
                      <QuestionAnswerGroupA
                        labelText={label(index, paymentFrequencyLabelLookup[productsEntity.paymentFrequency])}
                        value={toCurrency(productPremium(product.premium), currency, language)}
                        valueWeight={fontWeight || 600}
                        alignLabelWithValue={true}
                      />
                    </RowGrid>
                  </React.Fragment>
                ))}
              </Grid>

              <Grid container>
                {productsEntity.riders.map((rider: ProductsEntityProduct, index: number) => {
                  const riderAmount = rider.coverageAmount;
                  return (
                    <React.Fragment key={rider.productId}>
                      <Grid item xs={riderAmount === 0.0 ? 9 : 6}>
                        <QuestionAnswerGroupA
                          labelText={label(index, 'productInformation.riderDescription')}
                          value={rider.name[language] || ''}
                          valueWeight={fontWeight || 600}
                          alignLabelWithValue={true}
                        />
                      </Grid>
                      {riderAmount !== 0.0 && (
                        <Grid item xs={3}>
                          <QuestionAnswerGroupA
                            labelText={label(index, 'productInformation.coverage')}
                            value={riderAmount === 0.0 ? zeroCoverageMask : toCurrency(riderAmount, currency, language)}
                            valueWeight={fontWeight || 600}
                            alignLabelWithValue={true}
                          />
                        </Grid>
                      )}

                      <Grid item xs={3}>
                        <QuestionAnswerGroupA
                          labelText={label(index, paymentFrequencyLabelLookup[productsEntity.paymentFrequency])}
                          value={toCurrency(rider.premium, currency, language)}
                          valueWeight={fontWeight || 600}
                          alignLabelWithValue={true}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>

              {typeof addons !== 'undefined' && addons.length !== 0 && (
                <Grid container>
                  {addons.map((addon: ProductAddon, index: number) => (
                    <React.Fragment key={index}>
                      <Grid item xs={9}>
                        <QuestionAnswerGroupA
                          labelText={label(index, 'productInformation.addonDescription')}
                          value={addon.description}
                          valueWeight={fontWeight || 600}
                          alignLabelWithValue={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <QuestionAnswerGroupA
                          labelText={label(index, paymentFrequencyLabelLookup[productsEntity.paymentFrequency])}
                          value={toCurrency(addon.premium, currency, language)}
                          valueWeight={fontWeight || 600}
                          alignLabelWithValue={true}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              )}

              {typeof premium !== 'undefined' && premium >= 0 && (
                <React.Fragment>
                  <Box mb={2} />

                  <Grid container>
                    <Grid item xs={9}>
                      <QuestionAnswerGroupA
                        value={translate(totalPaymentFrequencyLabelLookup[productsEntity.paymentFrequency], {
                          locale: language,
                        })}
                        valueWeight={fontWeight || 600}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <QuestionAnswerGroupA
                        value={toCurrency(premium, currency, language)}
                        valueWeight={fontWeight || 600}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          </Grid>
        </Grid>
      </ProductContainer>
    </Box>
  );
}
