import Box from '@material-ui/core/Box';
import React from 'react';

import { RenderingQuestion } from '@breathelife/questionnaire-engine';

import { FieldWrapperB } from '../../../PdfFieldGeneration/FieldVariantB/FieldWrapperB';

type Props = {
  fieldGroups: RenderingQuestion[];
};

let questionSequence = 1;
export function FieldGroups(props: Props): React.ReactElement {
  const { fieldGroups } = props;
  return (
    <div>
      {fieldGroups.map((fieldGroup) => {
        if (!fieldGroup.visible) return null;
        fieldGroup.index = String(questionSequence);
        questionSequence++;
        return (
          <Box key={fieldGroup.id} mb={2}>
            <FieldWrapperB fieldGroup={fieldGroup} />
          </Box>
        );
      })}
    </div>
  );
}
