import { RenderContext } from '@breathelife/field-generator';
import { NodeIdToAnswerPathMap, RenderingField, RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import {
  AdvisorEntity,
  Application,
  DynamicPdfTextOverrides,
  OneSpanESignSigner,
  InsuranceModule,
  IsoCountryCode,
  Localizable,
  OwnerEntity,
  PayerEntity,
  PlatformType,
  ProductAddon,
  ProductsEntity,
  ProposedInsuredEntity,
  PdfVariant,
  DynamicPdfComponentSelection,
  Language,
  ParticipantRoles,
  DynamicPdfType,
  PdfDocumentType,
  IsSmoker,
  DynamicPdfFunctionOverrides,
  ApplicationPointOfSaleDecisions,
} from '@breathelife/types';
import { SharedTheme } from '@breathelife/ui-components';

export enum SigneeLabel {
  default = 'default', // Return the default label defined in Localization
  name = 'name', // Return persons first and last name
  none = 'none', // Return nothing
}

// OneSpan Text Tag Field Type
export enum SignatureFieldType {
  signature = 'signature',
  initials = 'initials',
  capture = 'capture',
  mobile_capture = 'mobile_capture',
}

// OneSpan Text Tag Field Type
export enum AutofieldFieldType {
  signerName = 'signerName',
  signature = 'signature',
  signerTitle = 'signerTitle',
  signerCompany = 'signerCompany',
  signerDate = 'signerDate',
}

// OneSpan Text Tag Field Type
export enum InputFieldType {
  textField = 'textField',
  textArea = 'textArea',
  list = 'list',
  radio = 'radio',
  checkbox = 'checkbox',
  label = 'label',
}

export type SignerDetails = {
  title?: Localizable;
  text?: Localizable;
};

export type SignatureDetails = Partial<Record<ParticipantRoles, SignerDetails>>;

export type Signers = Partial<Record<ParticipantRoles, OneSpanESignSigner[]>>;

export type BaseEnabledPdfOptions<PdfOptions> = ({ enabled: true } & PdfOptions) | { enabled: false };

export type DynamicPdfProps = {
  carrierPdfOptions: BaseEnabledPdfOptions<CarrierPdfOptions>;
  insuredPdfOptions: BaseEnabledPdfOptions<InsuredPdfOptions>;
  advisorPdfOptions: BaseEnabledPdfOptions<AdvisorPdfOptions>;
  theme?: SharedTheme;
  countryCode: IsoCountryCode;
  pdfTextOverrides?: DynamicPdfTextOverrides;
  signatureOptions?: { eSignatureFieldType?: SignatureFieldType };
};

export type BasePdfOptions = {
  title?: string;
  insuranceModuleFilter?: InsuranceModule[];
  platformTypeFilter?: PlatformType[];
  renderContext?: RenderContext;
  displaySigneeLabel?: SigneeLabel;
  timezone?: string;
  useIndividualNameFields?: boolean;
  pdfTextOverrides?: DynamicPdfTextOverrides;
  variant?: PdfVariant;
  componentSelection?: DynamicPdfComponentSelection;
  pdfFunctionOverrides?: Partial<DynamicPdfFunctionOverrides>;
};

export type InsuredPdfOptions = BasePdfOptions & {
  // TODO: rename to displayLegacyProductCoverageInfo
  legacyDisplayProductCoverageInfo?: boolean;
};
export type CarrierPdfOptions = BasePdfOptions;
export type AdvisorPdfOptions = BasePdfOptions;

export type PdfData = {
  renderingQuestionnaire: RenderingQuestionnaire;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
  application: Application;
  currency: string;
  cryptoSignatureData?: CryptoSignatureData;

  // TODO: update signees or replace type with entity structure
  signatureData?: SignatureData;
  proposedInsuredInformation?: ProposedInsuredEntity | null;
  productsEntity?: ProductsEntity;
  advisors?: AdvisorEntity[];
  addons?: ProductAddon[];
  payorInformation?: PayerEntity[];
  ownerInformation?: OwnerEntity[];
  pdfType: DynamicPdfType;
  pointOfSaleDecisions?: ApplicationPointOfSaleDecisions | null;
  isSmoker: IsSmoker;
};

export type CryptoSignatureData = {
  signature?: string;
  date?: Date;
  payerIdentifier?: string;
};

export type SignatureData = {
  signers: Signers;
  signatureDetails?: SignatureDetails;
  isExternalSignature: boolean;
  eSignatureFieldType?: SignatureFieldType;
};

export type EnabledPdfOptions =
  | BaseEnabledPdfOptions<InsuredPdfOptions>
  | BaseEnabledPdfOptions<CarrierPdfOptions>
  | BaseEnabledPdfOptions<AdvisorPdfOptions>;
export type PdfOptions = InsuredPdfOptions | CarrierPdfOptions | AdvisorPdfOptions;

export type ReactElementList = Partial<Record<string, React.FunctionComponent>>;

export type RenderedFieldProps = {
  field: RenderingField;
  label: string;
  locale?: Language;
  subtitle?: string;
  fieldIndex?: string;
};

export const pdfTypeLookup: Record<DynamicPdfType, PdfDocumentType> = {
  [DynamicPdfType.advisor]: PdfDocumentType.advisorsReport,
  [DynamicPdfType.carrier]: PdfDocumentType.carrierReport,
  [DynamicPdfType.insured]: PdfDocumentType.application,
};
