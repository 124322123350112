import { Box } from '@material-ui/core';
import React from 'react';

import { DynamicPdfComponentSpecification, PdfVariant } from '@breathelife/types';

import { ComponentFactory } from '../../Components/PdfComponents/ComponentVariants/ComponentFactory';
import usePdfContext from '../../Context/usePdfContext';

export function CarrierPdf(): React.ReactElement | null {
  const { componentSelection, variant } = usePdfContext();

  if (typeof variant === 'undefined' || variant in PdfVariant === false) {
    throw new Error(`Pdf variant undefined or not valid.`);
  }
  if (typeof componentSelection === 'undefined' || componentSelection?.length === 0) {
    throw new Error(`No components selected.`);
  }

  const renderedComponents = componentSelection.map((component: DynamicPdfComponentSpecification, index: number) => {
    return <React.Fragment key={index}>{ComponentFactory(component, variant)}</React.Fragment>;
  });

  return <Box p={2}>{renderedComponents}</Box>;
}
