export default {
  A: {
    header: 'Proposed Insured Information',
    fullName: 'Full Name',
    title: 'Title',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    suffix: 'Suffix',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    dateOfBirth: 'Date of Birth',
    smokerStatus: 'Smoker Status',
    smoker: 'Smoker',
    nonSmoker: 'Non-smoker',
    address: 'Address',
    phoneNumber: 'Phone Number',
    email: 'Email',
    previousFirstName: 'Previous First Name',
    previousLastName: 'Previous Last Name',
    occupation: 'Occupation',
    canadianCitizenship: 'Canadian Citizenship',
    countryOfBirth: 'Country of Birth',
    provinceOfBirth: 'Province of Birth',
    address2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Postal Code',
    yes: 'Yes',
    no: 'No',
  },
  B: {},
};
