import I18n, { TranslateOptions } from 'i18n-js';
import _ from 'lodash';

import { DynamicPdfTextOverrides, ComponentsVariantsTree, Language } from '@breathelife/types';

import LocalizedStrings from './';

function buildLanguageVariantsTextOverrides(
  componentsVariants: ComponentsVariantsTree,
  language: Language
): DynamicPdfTextOverrides {
  const {
    advisorInformationStrings,
    insuranceFirmInformationStrings,
    ownerInformationStrings,
    payorInformationStrings,
    pdfTitleStrings,
    productInformationStrings,
    proposedInsuredInformationStrings,
    submissionDateStrings,
    carrierInformationStrings,
  } = LocalizedStrings;

  const {
    advisorInformationVariant,
    insuranceFirmInformationVariant,
    ownerInformationVariant,
    payorInformationVariant,
    pdfTitleVariant,
    productInformationVariant,
    proposedInsuredInformationVariant,
    submissionDateVariant,
    carrierInformationVariant,
  } = componentsVariants;

  return {
    [language]: {
      advisorInformation: advisorInformationStrings[language][advisorInformationVariant],
      insuranceFirmInformation: insuranceFirmInformationStrings[language][insuranceFirmInformationVariant],
      ownerInformation: ownerInformationStrings[language][ownerInformationVariant],
      payorInformation: payorInformationStrings[language][payorInformationVariant],
      pdfTitle: pdfTitleStrings[language][pdfTitleVariant],
      productInformation: productInformationStrings[language][productInformationVariant],
      proposedInsuredInformation: proposedInsuredInformationStrings[language][proposedInsuredInformationVariant],
      submissionDate: submissionDateStrings[language][submissionDateVariant],
      carrierInformation: carrierInformationStrings[language][carrierInformationVariant],
    },
  };
}

//TODO: Need to strongly type the dynamic pdf overrides. Jira: DEV-9551
export function setDynamicPdfTranslations(
  carrierDynamicPdfTranslations: DynamicPdfTextOverrides,
  componentsVariants: ComponentsVariantsTree
): void {
  const englishVarientsTextOverrides = buildLanguageVariantsTextOverrides(componentsVariants, Language.en);
  const frenchVarientsTextOverrides = buildLanguageVariantsTextOverrides(componentsVariants, Language.fr);
  I18n.translations = {
    en: _.merge({}, LocalizedStrings.en, englishVarientsTextOverrides, carrierDynamicPdfTranslations.en),
    fr: _.merge({}, LocalizedStrings.fr, frenchVarientsTextOverrides, carrierDynamicPdfTranslations.fr),
  };
}

export function translate(value: string, options?: TranslateOptions): string {
  return I18n.t(value, options);
}
