export default {
  A: {
    header: "Informations sur l'assuré proposé",
    fullName: 'Nom et prénom',
    title: 'Titre',
    firstName: 'Prénom',
    middleName: 'Deuxième nom',
    lastName: 'Nom de famille',
    suffix: 'Suffixe',
    gender: 'Genre',
    male: 'Homme',
    female: 'Femme',
    dateOfBirth: 'Date de naissance',
    smokerStatus: 'Statut de fumeur',
    smoker: 'Fumeur',
    nonSmoker: 'Non-fumeur',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Courriel',
    previousFirstName: 'Prénom précédent',
    previousLastName: 'Nom de famille précédent',
    occupation: 'Occupation',
    canadianCitizenship: 'Citoyenneté canadienne',
    countryOfBirth: 'Pays de naissance',
    provinceOfBirth: 'Province de naissance',
    address2: 'Adresse 2',
    city: 'Ville',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Code postal',
    yes: 'Oui',
    no: 'Non',
  },
  B: {},
};
