import React from 'react';

import {
  DynamicPdfComponentName,
  DynamicPdfComponentProps,
  DynamicPdfComponentSpecification,
  PdfVariant,
} from '@breathelife/types';

import { componentVariantLookUp } from './ComponentVariantLookUp';

export function ComponentFactory(
  component: DynamicPdfComponentSpecification,
  defaultVariant: PdfVariant
): React.ReactElement | null {
  const selectedVariant =
    typeof component.settings?.variantOverride !== 'undefined' ? component.settings.variantOverride : defaultVariant;
  const componentVariant = getComponentVariant(component.name, selectedVariant);

  if (!componentVariant) {
    throw Error(`Component ${component.name} has not been defined for variant ${selectedVariant}.`);
  }

  return React.createElement(componentVariant);
}

/* TODO: Improve loading performance - DEV-10480 */
export function getComponentVariant(
  componentType: DynamicPdfComponentName,
  selectedVariant: PdfVariant
): React.FunctionComponent<DynamicPdfComponentProps> | null {
  return componentVariantLookUp[selectedVariant][componentType] ?? null;
}
