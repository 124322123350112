import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { DynamicPdfType, SignatureType } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { setAuthorizationInterceptor } from '../Services/ApiService';
import { getCurrency } from '../helpers/currency';
import { isTimezoneValid } from '../helpers/isTimezoneValid';
import { DynamicPdfProps, EnabledPdfOptions } from '../types';
import { DataFetchingContainer } from './DataFetchingContainer';

const CenteredText = styled.div`
  text-align: center;
`;

function getAppropriatePdfOptionsFromPdfType(
  type: string,
  pdfProps: Omit<DynamicPdfProps, 'theme' | 'countryCode'>
): EnabledPdfOptions {
  switch (type) {
    case DynamicPdfType.carrier:
      return pdfProps.carrierPdfOptions;
    case DynamicPdfType.insured:
      return pdfProps.insuredPdfOptions;
    case DynamicPdfType.advisor:
      return pdfProps.advisorPdfOptions;
    default:
      throw Error(`Pdf type parameter ${type} not yet supported by the dynamic pdf`);
  }
}

function PdfContainerComponent(props: DynamicPdfProps): React.ReactElement | null {
  // TODO: The theme should be loaded in the db once the carrier themes are in the db
  const { theme, carrierPdfOptions, insuredPdfOptions, advisorPdfOptions, countryCode, signatureOptions } = props;
  const [authTokenSet, setAuthTokenSet] = useState(false);
  const [signatureType, setSignatureType] = useState<SignatureType>();
  const { search } = useLocation();
  const { type, applicationId } = useParams<{ type: string; applicationId: string }>();

  const pdfOptions = useMemo(
    () =>
      getAppropriatePdfOptionsFromPdfType(type, {
        insuredPdfOptions,
        carrierPdfOptions,
        advisorPdfOptions,
      }),
    [type, insuredPdfOptions, carrierPdfOptions, advisorPdfOptions]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    const signatureTypeParams = urlParams.get('signatureType');
    const signatureTypes = Object.values(SignatureType);

    if (!signatureType && signatureTypeParams && signatureTypes.includes(signatureTypeParams as SignatureType)) {
      setSignatureType(signatureTypeParams as SignatureType);
    }

    if (!authTokenSet && token) {
      setAuthorizationInterceptor(token);
      setAuthTokenSet(true);
    }
  }, [search, authTokenSet, signatureType]);

  if (!authTokenSet) {
    return <Loader color='#000000' />;
  }

  if (!pdfOptions.enabled) {
    return <CenteredText>{`The pdf was not generated because pdf type ${type} is not enabled`}</CenteredText>;
  }

  const timezone = pdfOptions.timezone;

  if (timezone && !isTimezoneValid(timezone)) {
    throw new Error(`The pdf was not generated because the timezone ${timezone} is not valid`);
  }

  const currency = getCurrency(countryCode);

  return (
    <DataFetchingContainer
      applicationId={applicationId}
      pdfType={type as DynamicPdfType}
      theme={theme}
      pdfOptions={pdfOptions}
      currency={currency}
      signatureType={signatureType}
      eSignatureFieldType={signatureOptions?.eSignatureFieldType}
    />
  );
}

export const PdfContainer = React.memo(PdfContainerComponent, _.isEqual);
