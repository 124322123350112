import advisorInformationStrings from './AdvisorInformation/index';
import carrierInformationStrings from './CarrierInformation/index';
import insuranceFirmInformationStrings from './InsuranceFirmInformation/index';
import ownerInformationStrings from './OwnerInformation/index';
import payorInformationStrings from './PayorInformation/index';
import pdfTitleStrings from './PdfTitle/index';
import productInformationStrings from './ProductInformation/index';
import proposedInsuredInformationStrings from './ProposedInsuredInformation/index';
import submissionDateStrings from './SubmissionDate/index';
import english from './english';
import french from './french';

export default {
  fr: french,
  en: english,
  advisorInformationStrings,
  insuranceFirmInformationStrings,
  ownerInformationStrings,
  payorInformationStrings,
  pdfTitleStrings,
  productInformationStrings,
  proposedInsuredInformationStrings,
  submissionDateStrings,
  carrierInformationStrings,
};
