import React from 'react';
import styled from 'styled-components';

import { InfoVariants } from '@breathelife/questionnaire-engine';

type VariantProps = {
  variant: InfoVariants;
};

function InfoSvg(props: { className?: string }): React.ReactElement {
  return (
    <svg
      className={props.className}
      fill='none'
      height='22'
      viewBox='0 0 32 32'
      width='22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m16.0001 31.142c8.3627 0 15.142-6.7793 15.142-15.142 0-8.36267-6.7793-15.141953-15.142-15.141953-8.36266 0-15.141946 6.779283-15.141946 15.141953 0 8.3627 6.779286 15.142 15.141946 15.142z'
        fill='#edf0f9'
      />
      <g>
        <path
          clipRule='evenodd'
          d='m16 1.71609c-7.88879 0-14.28391 6.39512-14.28391 14.28391 0 7.8888 6.39512 14.2839 14.28391 14.2839 7.8888 0 14.2839-6.3951 14.2839-14.2839 0-7.88879-6.3951-14.28391-14.2839-14.28391zm-16 14.28391c0-8.83656 7.16344-16 16-16 8.8366 0 16 7.16344 16 16 0 8.8366-7.1634 16-16 16-8.83656 0-16-7.1634-16-16z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m16 13c.5523 0 1 .5373 1 1.2v9.6c0 .6627-.4477 1.2-1 1.2s-1-.5373-1-1.2v-9.6c0-.6627.4477-1.2 1-1.2z'
          fillRule='evenodd'
        />
        <path d='m15.9999 6.91484c-.3993 0-.7897.11841-1.1217.34025s-.5908.53716-.7436.90607-.1927.77484-.1148 1.16648c.0779.39163.2701.75136.5525 1.03376.2823.2823.6421.4746 1.0337.5525s.7976.0379 1.1665-.1149.6842-.4116.9061-.7436c.2218-.33199.3402-.72233.3402-1.12164.0007-.26533-.051-.52818-.1522-.77345-.1012-.24526-.2499-.46811-.4375-.65572-.1877-.18762-.4105-.33631-.6558-.43752-.2452-.1012-.5081-.15294-.7734-.15223z' />
      </g>
    </svg>
  );
}

const StyledIcon = styled(InfoSvg).withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})<VariantProps>`
  g {
    fill: ${(props) => props.theme.colors[props.variant]};
  }
`;

export function InformationIcon(): React.ReactElement {
  return <StyledIcon variant={InfoVariants.info} />;
}

export function SuccessIcon(): React.ReactElement {
  return <StyledIcon variant={InfoVariants.success} />;
}

export function WarningIcon(): React.ReactElement {
  return <StyledIcon variant={InfoVariants.warning} />;
}

export function ErrorIcon(): React.ReactElement {
  return <StyledIcon variant={InfoVariants.error} />;
}
