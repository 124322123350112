export default {
  A: {
    header: 'Information sur le propriétaire',
    fullName: 'Nom et prénom',
    title: 'Titre',
    firstName: 'Prénom',
    middleName: 'Deuxième nom',
    lastName: 'Nom de famille',
    suffix: 'Suffixe',
    dateOfBirth: 'Date de naissance',
    address: 'Adresse',
    phoneNumber: 'Numéro de téléphone',
    email: 'Courriel',
    address2: 'Adresse 2',
    city: 'Ville',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Code postal',
  },
  B: {},
};
