export default {
  A: {
    coverageInformation: 'Coverage Information',
    product: {
      one: 'Product',
      other: 'Products',
    },
    productDescription: 'Product Name',
    coverage: 'Coverage',
    monthlyPremium: 'Monthly Premium',
    addonOptionPrefix: 'Addon: ',
    addonDescription: 'Addon Name',
    includedAddon: 'Included',
    totalMonthlyPremium: 'Total Monthly Premium',
    riderDescription: 'Rider Name',
  },
  B: {},
};
