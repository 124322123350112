import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { PdfFieldGroupHeader } from '@breathelife/ui-components';

import { CryptoStamp } from '../../../../Assets/Images/CryptoStamp';
import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

const CryptoSignatureContainer = styled.div`
  & {
    break-inside: avoid;
  }
`;

export function CryptoSignature(): React.ReactElement {
  const { application, proposedInsuredInformation, payorInformation = [], cryptoSignatureData } = usePdfContext();
  const { lang: language } = application;
  const signeeName: string = `${proposedInsuredInformation?.firstName || ''} ${
    proposedInsuredInformation?.lastName || ''
  }`.trim();

  const formattedDate = dayjs(cryptoSignatureData?.date).locale(language).format('LL');

  const payerSigneeName: string = `${payorInformation[0]?.firstName ?? ''} ${
    payorInformation[0]?.lastName ?? ''
  }`.trim();

  const payerType = application.answers.insuredPeople[0].payment.hasDifferentPayer;

  return (
    <Box mb={3}>
      <CryptoSignatureContainer data-testid='CryptoSignature'>
        <PdfFieldGroupHeader> {translate('signature.signatures', { locale: language })}</PdfFieldGroupHeader>
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <QuestionAnswerGroupA
                  labelText={translate('signature.type.electronic', { locale: language })}
                  value={cryptoSignatureData?.signature ?? ''}
                />
              </Grid>
              <Grid item xs={6}>
                <QuestionAnswerGroupA
                  labelText={translate('signature.INSURED', { locale: language, count: 1 })}
                  value={signeeName}
                />
              </Grid>
              <Grid item xs={6}>
                <QuestionAnswerGroupA
                  labelText={translate('signature.date', { locale: language })}
                  value={formattedDate}
                />
              </Grid>
            </Grid>
            {typeof payerType !== 'undefined' && payerType !== 'false' && payerSigneeName && (
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6}>
                    <QuestionAnswerGroupA
                      labelText={translate('signature.PAYER', { locale: language, count: 1 })}
                      value={payerSigneeName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <QuestionAnswerGroupA
                      labelText={translate('signature.date', { locale: language })}
                      value={formattedDate}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={6}>
            <CryptoStamp language={language} />
          </Grid>
        </Grid>
      </CryptoSignatureContainer>
    </Box>
  );
}
