import { Box, Grid } from '@material-ui/core';
import React from 'react';

import { GridPropsWithText, VariantText } from '../Styles/FieldVariantBStyles';

export function AnswerGrid(props: GridPropsWithText): React.ReactElement {
  const { text, ...gridProps } = props;
  return (
    <Grid {...gridProps}>
      <Box textAlign='right' pl={3}>
        <VariantText>{text}</VariantText>
      </Box>
    </Grid>
  );
}
