import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingField, RenderingQuestion } from '@breathelife/questionnaire-engine';

import { FieldFactory } from '../FieldFactory';
import { GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';

type Props = {
  fieldGroup: RenderingQuestion;
};

export function FieldWrapperB(props: Props): React.ReactElement | null {
  const { fieldGroup } = props;

  fieldGroup.fields = fieldGroup.fields.filter((el) => el.visible);
  let subIndex = 0;
  return (
    <React.Fragment>
      <Grid container direction='row'>
        {fieldGroup.title && (
          <GridItem item xs={9} padding={'0px 0px 10px 10px'}>
            <SequenceNumber>{`${fieldGroup.index}.`}</SequenceNumber>
            <VariantText>{ReactHtmlParser(fieldGroup.title)}</VariantText>
          </GridItem>
        )}
        {fieldGroup.fields.map((field: RenderingField) => {
          if (typeof field.title !== 'undefined' && field.title !== '') subIndex++;
          return (
            <React.Fragment key={field.id}>
              <FieldFactory field={field} fieldIndex={`${fieldGroup.index}.${subIndex}. `} />
            </React.Fragment>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}
