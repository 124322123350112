import Grid from '@material-ui/core/Grid';
import React from 'react';

import { RenderingAgreeField, RenderingField } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import usePdfContext from '../../../Context/usePdfContext';
import { QuestionAnswerGroupA } from '../../PdfComponents/ComponentVariants/ComponentVariantsA/Styles/PdfQuestionAnswerGroupA';

type Props = {
  field: RenderingField;
};

export function AgreeField(props: Props): React.ReactElement | null {
  const { field } = props;
  const { application } = usePdfContext();

  const locale = application.lang;
  const agreeFieldValue =
    typeof field.title !== 'undefined' ? field.title : locale === Language.fr ? 'Je confirm' : 'I confim';

  return (
    <React.Fragment>
      <Grid item xs={12} key={field.id} data-testid='AgreeField'>
        <QuestionAnswerGroupA
          fieldText={(field as RenderingAgreeField).modalText}
          value={agreeFieldValue}
          valueWidth={6}
        />
      </Grid>
    </React.Fragment>
  );
}
