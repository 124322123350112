import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingSection } from '@breathelife/questionnaire-engine';

import {
  Title,
  P,
  PdfVariantFieldGroupHeader,
  Separator,
  SubSectionContainer,
} from '../../ComponentVariants/ComponentVariantsB/Styles/PdfVariantBStyles';
import { FieldGroups } from './FieldGroups';

type SubsectionsProps = {
  section: RenderingSection;
};

export function Subsections(props: SubsectionsProps): React.ReactElement {
  const { section } = props;
  const { subsections, title: sectionTitle } = section;

  return (
    <div>
      {subsections.map((subsection) => {
        if (!subsection.visible) return null;
        const { pageBreakSubSectionInPdf } = subsection;
        return (
          <SubSectionContainer key={subsection.id} pageBreak={pageBreakSubSectionInPdf ?? false}>
            <Box mb={3}>
              {!sectionTitle ? (
                <React.Fragment>
                  <Title>{ReactHtmlParser(subsection.title)}</Title>
                  <Separator />
                </React.Fragment>
              ) : (
                <PdfVariantFieldGroupHeader>{ReactHtmlParser(subsection.title)}</PdfVariantFieldGroupHeader>
              )}
              {subsection.text && (
                <Box mb={2}>
                  <P>{ReactHtmlParser(subsection.text)}</P>
                </Box>
              )}
              <FieldGroups fieldGroups={subsection.questions} />
            </Box>
          </SubSectionContainer>
        );
      })}
    </div>
  );
}
