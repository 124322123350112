import Box from '@material-ui/core/Box';
import React from 'react';

import { PdfDocumentTitle, PdfFieldText } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';

export function PdfTitle(): React.ReactElement {
  const { title, proposedInsuredInformation, application } = usePdfContext();
  const { lang: language } = application;

  const insuredName: string = `${proposedInsuredInformation?.firstName || ''} ${
    proposedInsuredInformation?.lastName || ''
  }`.trim();

  return (
    <Box mb={2}>
      <PdfDocumentTitle data-testid='PdfTitle'>{title}</PdfDocumentTitle>
      <PdfFieldText>{translate('pdfTitle.forInsuredName', { locale: language, insuredName })}</PdfFieldText>
    </Box>
  );
}
