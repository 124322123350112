import Grid from '@material-ui/core/Grid';
import React from 'react';

import { RenderingField, RenderingQuestion } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';

import usePdfContext from '../../../Context/usePdfContext';
import getComponentSettings from '../../../helpers/getComponentSettings';
import { FieldFactory } from '../FieldFactory';

type Props = {
  fieldGroup: RenderingQuestion;
};

export function FieldWrapperA(props: Props): React.ReactElement | null {
  const { fieldGroup } = props;

  const { componentSelection = [] } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { alignFieldsTop } = questionnaireSettings;

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems={alignFieldsTop ? 'flex-start' : 'flex-end'}>
        {fieldGroup.fields.map((field: RenderingField) => (
          <React.Fragment key={field.id}>
            <FieldFactory field={field} />
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}
