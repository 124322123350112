import React from 'react';
import styled from 'styled-components';

import { InfoVariants } from '@breathelife/questionnaire-engine';
import { Information as InformationPanel, InformationProps } from '@breathelife/ui-components';

import { ErrorIcon, InformationIcon, SuccessIcon, WarningIcon } from './Styles/Icons';

type Props = Pick<InformationProps, 'title' | 'text' | 'locale' | 'variant'>;

const iconMap = {
  [InfoVariants.info]: InformationIcon,
  [InfoVariants.error]: ErrorIcon,
  [InfoVariants.success]: SuccessIcon,
  [InfoVariants.warning]: WarningIcon,
};

const StyledInformation = styled(InformationPanel)`
  padding: 26px 24px 26px 32px;
  border-width: 1px;

  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export function Information(props: Props): React.ReactElement {
  return <StyledInformation {...props} iconMap={iconMap} borderStyle='rounded' iconVerticalAlignment='center' />;
}
