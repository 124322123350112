import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingAgreeField, RenderingField } from '@breathelife/questionnaire-engine';

import usePdfContext from '../../../Context/usePdfContext';
import { translate } from '../../../Localization/Localizer';
import { AnswerGrid } from './Helpers/AnswerGrid';
import { GridContainer, GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';

type Props = {
  field: RenderingField;
  fieldIndex: string;
};

export function AgreeField(props: Props): React.ReactElement {
  const { field, fieldIndex } = props;

  const { application } = usePdfContext();

  const locale = application.lang;

  const modalText = (field as RenderingAgreeField).modalText ?? '';
  const valueText = String(field.value) ? translate('yes', { locale }) : translate('no', { locale });

  return (
    <GridContainer container direction='row'>
      <GridItem item xs={9}>
        <SequenceNumber>{fieldIndex}</SequenceNumber>
        <Grid>
          <VariantText>{ReactHtmlParser(modalText)}</VariantText>
          {field.text && <VariantText grey={80}>{ReactHtmlParser(field.text)}</VariantText>}
        </Grid>
      </GridItem>
      <AnswerGrid item xs={3} text={valueText} />
    </GridContainer>
  );
}
