import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Language } from '@breathelife/types';

// Configure dayjs to support timezones and Do
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export function formatDate(date: Date | string, locale: Language, enableFullDate?: boolean): string {
  const dateJs = dayjs.utc(date);
  if (enableFullDate) {
    // English format e.g. April 1, 2022, April 19, 2022
    // French format e.g. 1er avril 2022, 19 avril 2022 https://www.btb.termiumplus.gc.ca/tpv2guides/guides/clefsfp/index-fra.html?lang=fra&lettr=indx_catlog_d&page=9lcOojjUrxt8.html
    const dayFormat = dateJs.date() === 1 ? 'Do' : 'D';
    const localeFormat = locale === Language.fr ? `${dayFormat} MMMM YYYY` : 'MMMM D, YYYY';
    return dateJs.locale(locale).format(localeFormat);
  }
  return dateJs.format('YYYY-MM-DD');
}

export function formatDateWithTime(
  date: Date | string,
  locale: Language,
  timezone: string,
  enableFullDate?: boolean
): string {
  const dateJs = dayjs(date).tz(timezone);
  if (enableFullDate) {
    // English format e.g. April 1, 2022 08:55 MDT, April 19, 2022 06:57 MDT
    // French format e.g. 1er avril 2022 08h55 MDT, 19 avril 2022 18h57 MDT https://www.btb.termiumplus.gc.ca/tpv2guides/guides/clefsfp/index-fra.html?lang=fra&lettr=indx_catlog_d&page=9lcOojjUrxt8.html
    const dayFormat = dateJs.date() === 1 ? 'Do' : 'D';
    const localeFormat = locale === Language.fr ? `${dayFormat} MMMM YYYY HH[h]mm z` : 'MMMM D, YYYY hh:mm A z';
    return dateJs.locale(locale).format(localeFormat);
  }
  return locale === Language.fr ? dateJs.format('YYYY-MM-DD HH[h]mm z') : dateJs.format('YYYY-MM-DD hh:mm A z');
}
