export default {
  A: {
    header: 'Owner Information',
    fullName: 'Full Name',
    title: 'Title',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    suffix: 'Suffix',
    dateOfBirth: 'Date of Birth',
    address: 'Address',
    phoneNumber: 'Phone Number',
    email: 'Email',
    address2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province', // FIXME: Determine province or state
    postalCode: 'Postal Code',
  },
  B: {},
};
