import CreateIcon from '@material-ui/icons/Create';
import styled from 'styled-components';

export const SignatureEntityContainer = styled.div`
  display: inline-block;
  margin-bottom: 8px;
  width: 50%;
  vertical-align: top;
`;

export const SectionTable = styled.table`
  text-align: left;
  width: 100%;
`;

export const SectionTableHeader = styled.thead`
  border-bottom: 1px double ${(props) => props.theme.colors.text.light};
`;

export const SectionTableFooter = styled.tfoot`
  & th:first-of-type {
    text-align: right;
  }
`;

export const SectionTableRow = styled.tr``;

export const ContentRow = styled(SectionTableRow)`
  & > td {
    padding-top: 4px;
  }
`;

export const SubContentRow = styled(SectionTableRow)`
  & > td {
    padding-top: 4px;

    &:first-child {
      padding-left: 12px;
    }
  }
`;

export const TableLine = styled.td`
  border-bottom: 1px solid black;
`;

export const RightAlignContent = styled.td`
  text-align: right;
  padding-right: 0.75em;
`;

export const Separator = styled.hr`
  height: 1;
  background-color: ${(props) => props.theme.colors.text.base};
`;

export const SignatureFieldIcon = styled(CreateIcon)`
  font-size: 24px;
  margin: 1px;
  padding-right: 8px;
`;

export const SignatureLine = styled.div`
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.grey[100]};
  height: 1px;
`;

export const SectionContainer = styled.div`
  break-inside: avoid;
`;
