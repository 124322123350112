import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingOptionField } from '@breathelife/questionnaire-engine';

import usePdfContext from '../../../Context/usePdfContext';
import { translate } from '../../../Localization/Localizer';
import { getFieldLabel, optionIsSelected } from '../../../helpers/fieldHelpers';
import { RenderedFieldProps } from '../../../types';
import { LabelGrid } from './Helpers/LabelGrid';
import { GridContainer, GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';

export function CheckboxGroup(props: RenderedFieldProps): React.ReactElement | null {
  const { field, fieldIndex } = props;

  const { application } = usePdfContext();

  const locale = application.lang;

  const fieldLabel = getFieldLabel(field);
  const options = (field as RenderingOptionField).options;

  return (
    <React.Fragment>
      {options ? (
        <React.Fragment>
          {fieldLabel && <LabelGrid text={fieldLabel} subtitle={field.text} fieldIndex={fieldIndex} />}
          {options.map((option, index) => {
            if (option.id == 'none' || option.id == 'aucuneDeCesRéPonses') return null;
            const optionValue = optionIsSelected(option.id, field.value) ? translate('yes') : translate('no');
            return (
              <GridContainer
                key={option.id}
                container
                direction='row'
                style={{ paddingLeft: fieldLabel ? '70px' : '35px' }}
              >
                <GridItem item xs={9}>
                  <SequenceNumber minWidth={'12px'}>{`${String.fromCharCode(97 + index)}. `}</SequenceNumber>
                  <VariantText>{ReactHtmlParser(option.text)}</VariantText>
                </GridItem>
                <Grid item xs={3}>
                  <VariantText align='right' weight={800}>
                    {optionValue}
                  </VariantText>
                </Grid>
              </GridContainer>
            );
          })}
        </React.Fragment>
      ) : (
        <GridContainer key={field.id} container direction='row'>
          <GridItem item xs={9}>
            <SequenceNumber>{fieldIndex}</SequenceNumber>
            <VariantText>{field.title}</VariantText>
          </GridItem>
          <Grid item xs={3}>
            <VariantText align='right' weight={800}>
              {String(field.value) ? translate('yes', { locale }) : translate('no', { locale })}
            </VariantText>
          </Grid>
        </GridContainer>
      )}
    </React.Fragment>
  );
}
