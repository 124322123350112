export default {
  A: {
    header: "Informations sur la compagnie d'assurance",
    name: 'Nom',
    address: 'Adresse postale',
    primaryPhoneNumber: 'Numéro de téléphone principal',
    tollFreePhoneNumber: 'Numéro de téléphone sans frais',
    email: 'Courriel',
    website: 'Site web',
  },
  B: {},
};
