import React from 'react';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatPhoneNumber } from './Helpers/variantPdfHelper';
import { renderColumnBlockWithIndent } from './RenderColumn';
import { Column, ColumnContainer, Section, Separator, Title } from './Styles/PdfVariantBStyles';

export function InsuranceFirmInformation(): React.ReactElement | null {
  const { application, productsEntity } = usePdfContext();
  const { lang: language } = application;
  if (!productsEntity?.products?.length) {
    return null;
  }

  const insuranceFirm = productsEntity.products[0].firm;

  const areAllInsuranceFirmsEqual = productsEntity.products.every(
    (item) => item.firm.name[language] === insuranceFirm.name[language]
  );

  if (!areAllInsuranceFirmsEqual) {
    throw new Error('Pdf was not generated: selected products belong to different firms');
  }

  const { name, primaryPhoneNumber, tollFreePhoneNumber, email, address, websiteUrl } = insuranceFirm;

  return (
    <Section>
      <Title>Insurance Firm</Title>
      <Separator />
      <ColumnContainer>
        <Column width={65}>
          {renderColumnBlockWithIndent(
            translate('insuranceFirmInformation.name', { locale: language }),
            name[language] || ''
          )}
          {renderColumnBlockWithIndent(
            translate('insuranceFirmInformation.address', { locale: language }),
            address?.[language] || ''
          )}
          {renderColumnBlockWithIndent(translate('insuranceFirmInformation.email', { locale: language }), email ?? '')}
          {renderColumnBlockWithIndent(
            translate('insuranceFirmInformation.primaryPhoneNumber', { locale: language }),
            formatPhoneNumber(primaryPhoneNumber ?? '')
          )}
          {renderColumnBlockWithIndent(
            translate('insuranceFirmInformation.tollFreePhoneNumber', { locale: language }),
            formatPhoneNumber(tollFreePhoneNumber ?? '')
          )}
          {renderColumnBlockWithIndent(
            translate('insuranceFirmInformation.website', { locale: language }),
            websiteUrl ?? ''
          )}
        </Column>
      </ColumnContainer>
    </Section>
  );
}
