import { DynamicPdfComponentName, DynamicPdfComponentSelection, DynamicPdfComponentSettings } from '@breathelife/types';

function getComponentSettings(
  componentSelection: DynamicPdfComponentSelection,
  componentName: DynamicPdfComponentName
): DynamicPdfComponentSettings {
  const selectedComponent = componentSelection.find((component) => {
    return component.name === componentName;
  });
  return selectedComponent ? selectedComponent.settings || {} : {};
}

export default getComponentSettings;
