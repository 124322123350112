import React from 'react';

import { FieldTypes, RenderingField } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName, DynamicPdfFieldProps, PdfVariant } from '@breathelife/types';

import usePdfContext from '../../Context/usePdfContext';
import { isUnansweredField } from '../../helpers/fieldHelpers';
import getComponentSettings from '../../helpers/getComponentSettings';
import { fieldVariantLookUp } from './FieldVariantLookUp';

type Props = {
  field: RenderingField;
  fieldIndex?: string;
};

export function FieldFactory(props: Props): React.ReactElement | null {
  const { field } = props;
  const { componentSelection = [], variant } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { variantOverride } = questionnaireSettings;

  const defaultVariant = variant ? variant : PdfVariant.A;

  if (!field.readOnly && (!field.visible || isUnansweredField(field))) {
    return null;
  }

  const selectedVariant = typeof variantOverride !== 'undefined' ? variantOverride : defaultVariant;
  const fieldVariant = getFieldVariant(field.type, selectedVariant);

  if (!fieldVariant) {
    throw Error(`Field ${field.type} has not been defined for variant ${selectedVariant}.`);
  }

  return React.createElement(fieldVariant, props);
}

/* TODO: Improve loading performance - DEV-10480 */
export function getFieldVariant(
  fieldType: FieldTypes,
  selectedVariant: PdfVariant
): React.FunctionComponent<DynamicPdfFieldProps> | null {
  return fieldVariantLookUp[selectedVariant][fieldType] ?? null;
}
