import dayjs from 'dayjs';
import _ from 'lodash';

import { FieldTypes, RenderingField, RenderingFieldOption } from '@breathelife/questionnaire-engine';
import { Language, LanguageRegion } from '@breathelife/types';

const LanguageRegions: { [lang: string]: LanguageRegion } = {
  [Language.fr]: LanguageRegion.frCa,
  [Language.en]: LanguageRegion.enCa,
};

export function getLanguageRegion(locale: Language): LanguageRegion {
  return LanguageRegions[locale];
}

export function isUnansweredField(field: RenderingField): boolean {
  return field.value === '' || typeof field.value === 'undefined';
}

export function optionIsSelected(optionValue: unknown, fieldValue: unknown | unknown[]): boolean {
  if (Array.isArray(fieldValue)) {
    return fieldValue.includes(optionValue);
  }
  return optionValue === fieldValue;
}

export function getFieldLabel(field: RenderingField): string {
  return (field.title || field.label) ?? '';
}

export function formatTextFieldValue(
  value: string | number | undefined,
  fieldType: FieldTypes,
  locale: Language,
  currency: string,
  options?: RenderingFieldOption[],
  displayFullDate?: boolean
): string {
  if (typeof value === 'undefined') {
    return '';
  }
  if (typeof value === 'number') {
    const currencyFieldTypes = [FieldTypes.money, FieldTypes.currencyCard];
    if (_.includes(currencyFieldTypes, fieldType)) {
      return Intl.NumberFormat(getLanguageRegion(locale), {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
      }).format(value);
    }
    return value.toString();
  }
  if (displayFullDate) {
    if (fieldType === FieldTypes.date) {
      // English format e.g. April 1, 2022, April 19, 2022
      // French format e.g. 1er avril 2022, 19 avril 2022 https://www.btb.termiumplus.gc.ca/tpv2guides/guides/clefsfp/index-fra.html?lang=fra&lettr=indx_catlog_d&page=9lcOojjUrxt8.html
      const dateJs = dayjs.utc(value);
      const dayFormat = dateJs.date() === 1 ? 'Do' : 'D';
      const localeFormat = locale === Language.fr ? `${dayFormat} MMMM YYYY` : 'MMMM D, YYYY';
      return dateJs.locale(locale).format(localeFormat);
    } else if (fieldType === FieldTypes.yearMonth) {
      // English format e.g. April 2022
      // French format e.g. avril 2022
      return dayjs.utc(value).locale(locale).format('MMMM YYYY');
    }
  }
  if (options) {
    return options.find((option) => option.id === value)?.text || '';
  }

  return value;
}
